import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  CssBaseline,
  Box,
  CircularProgress,
  Alert,
  Paper,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

function Login() {
  const navigate = useNavigate(); // Initialize navigation
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [savePassword, setSavePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Mock login request
      setTimeout(() => {
        if (email === "user@example.com" && password === "password") {
          alert("Login successful!");
          if (savePassword) {
            localStorage.setItem("savedEmail", email);
            localStorage.setItem("savedPassword", password);
            alert("Password saved!");
          }
        } else {
          setError("Invalid credentials, please try again.");
        }
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError("An error occurred. Please try again later.");
      setLoading(false);
    }
  };

  const handleRegister = () => {
    navigate("/register"); // Redirects to the /register route
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, mt: 8 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
            <TextField
              label="Email Address"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={savePassword}
                  onChange={(e) => setSavePassword(e.target.checked)}
                  color="primary"
                />
              }
              label="Save Password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Sign In"
              )}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={handleRegister} // Uses navigate instead of alert
            >
              Register
            </Button>
            <Box mt={2} textAlign="center">
              <Link href="" variant="body2" onClick={handleRegister}>
                Don't have an account? Register here.
              </Link>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
