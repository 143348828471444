import React, { useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Typography,
  TextField,
  Box,
  Alert,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent,
  CardActions,
  Radio,
  FormControlLabel,
  Checkbox,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PaymentIcon from "@mui/icons-material/Payment";
import StarIcon from "@mui/icons-material/Star";

function Dashboard() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [meals, setMeals] = useState(Array(7).fill({ name: "", price: "" }));
  const [restaurantProfile, setRestaurantProfile] = useState({
    name: "Ime Vašeg restorana",
    logo: null,
    phone: "",
    countryCode: "+387",
    additionalDescription: "Dnevna ponuda vrijedi do 13h",
    contactNumber: "+387",
    emailAddresses: ["", "", ""],
    companyName: "",
    companyAddress: "",
    postalCode: "",
    city: "",
    JIBNumber: "",
    isVATRegistered: false,
  });
  const [selectedPlan, setSelectedPlan] = useState("PRO");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeTab, setActiveTab] = useState("Dnevna jela");

  const handleLogout = () => {
    navigate("/login");
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  // Input sanitization function
  const sanitizeInput = (input) => {
    return input.replace(/[<>/'"`\\;]/g, "");
  };

  const handleMealChange = (index, field, value) => {
    const sanitizedValue = sanitizeInput(value);
    const updatedMeals = meals.map((meal, i) =>
      i === index ? { ...meal, [field]: sanitizedValue } : meal
    );
    setMeals(updatedMeals);
  };

  const handleSaveMeals = () => {
    const atLeastOneFilled = meals.some((meal) => meal.name && meal.price);
    if (atLeastOneFilled) {
      setSuccessMessage("Dnevna jela uspješno spremljena!");
      setErrorMessage(null);
    } else {
      setErrorMessage(
        "Molimo unesite barem jedno jelo i cijenu prije spremanja."
      );
      setSuccessMessage(null);
    }
  };

  const handleProfileChange = (field, value) => {
    const sanitizedValue = sanitizeInput(value);
    setRestaurantProfile({ ...restaurantProfile, [field]: sanitizedValue });
  };

  const handleEmailChange = (index, value) => {
    const sanitizedValue = sanitizeInput(value);
    const updatedEmails = [...restaurantProfile.emailAddresses];
    updatedEmails[index] = sanitizedValue;
    setRestaurantProfile({
      ...restaurantProfile,
      emailAddresses: updatedEmails,
    });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type and size if necessary
      const reader = new FileReader();
      reader.onload = () => {
        handleProfileChange("logo", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePaymentSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage("Plaćanje uspješno izvršeno!");
  };

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleProfileSave = () => {
    // Check if at least one email address is provided
    const atLeastOneEmailFilled = restaurantProfile.emailAddresses.some(
      (email) => email.trim() !== ""
    );

    if (!atLeastOneEmailFilled) {
      setErrorMessage("Molimo unesite barem jednu email adresu.");
      setSuccessMessage(null);
      return;
    }

    // Validate filled email addresses only
    const invalidEmails = restaurantProfile.emailAddresses.filter(
      (email) => email && !isValidEmail(email)
    );

    // Check if required fields are filled
    const requiredFields = [
      "companyName",
      "companyAddress",
      "postalCode",
      "city",
      "JIBNumber",
      "name",
      "additionalDescription",
      "contactNumber",
      "phone",
    ];

    const emptyFields = requiredFields.filter(
      (field) => !restaurantProfile[field]
    );

    if (emptyFields.length > 0) {
      setErrorMessage("Molimo popunite sva obavezna polja.");
      setSuccessMessage(null);
      return;
    }

    if (invalidEmails.length > 0) {
      setErrorMessage("Molimo unesite ispravne email adrese.");
      setSuccessMessage(null);
      return;
    }

    if (restaurantProfile.JIBNumber.length !== 12) {
      setErrorMessage("JIB broj mora imati točno 12 znamenki.");
      setSuccessMessage(null);
      return;
    }

    setSuccessMessage("Profil restorana uspješno ažuriran!");
    setErrorMessage(null);
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ px: 1, pb: 2 }}>
      <CssBaseline />
      <AppBar position="static" sx={{ mb: 2 }}>
        <Toolbar variant="dense">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="inherit"
            indicatorColor="secondary"
            variant={isMobile ? "fullWidth" : "standard"}
            centered={!isMobile}
          >
            <Tab label="Dnevna jela" value="Dnevna jela" />
            <Tab label="Profil restorana" value="Profil restorana" />
            <Tab label="Plaćanje" value="Plaćanje" />
            <Tab label="Odjava" onClick={handleLogout} />
          </Tabs>
        </Toolbar>
      </AppBar>

      {activeTab === "Dnevna jela" && (
        <Paper elevation={3} sx={{ padding: isMobile ? 2 : 3, mt: 1 }}>
          <Typography variant="h6" gutterBottom align="center">
            Dnevna jela
          </Typography>

          <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: "70%" }}>
                    Ime jela
                  </TableCell>
                  <TableCell align="center" sx={{ width: "30%" }}>
                    Cijena
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meals.map((meal, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: "70%" }}>
                      <TextField
                        label=""
                        variant="outlined"
                        fullWidth
                        size="small"
                        multiline
                        minRows={1}
                        value={meal.name}
                        onChange={(e) =>
                          handleMealChange(index, "name", e.target.value)
                        }
                        sx={{ my: 1 }}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "30%" }}>
                      <TextField
                        label=""
                        variant="outlined"
                        fullWidth
                        size="small"
                        type="number"
                        value={meal.price}
                        onChange={(e) =>
                          handleMealChange(index, "price", e.target.value)
                        }
                        sx={{ my: 1 }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box mt={2} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveMeals}
              fullWidth={isMobile}
            >
              SPREMI
            </Button>
          </Box>

          {successMessage && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Alert>
          )}
        </Paper>
      )}

      {activeTab === "Profil restorana" && (
        <Paper elevation={3} sx={{ padding: isMobile ? 2 : 3, mt: 1 }}>
          <Typography variant="h6" gutterBottom align="center">
            Profil restorana
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Puni naziv tvrtke"
              variant="outlined"
              fullWidth
              required
              value={restaurantProfile.companyName}
              onChange={(e) =>
                handleProfileChange("companyName", e.target.value)
              }
            />

            <TextField
              label="Adresa tvrtke"
              variant="outlined"
              fullWidth
              required
              value={restaurantProfile.companyAddress}
              onChange={(e) =>
                handleProfileChange("companyAddress", e.target.value)
              }
            />

            <TextField
              label="Poštanski broj"
              variant="outlined"
              fullWidth
              required
              value={restaurantProfile.postalCode}
              onChange={(e) =>
                handleProfileChange("postalCode", e.target.value)
              }
            />

            <TextField
              label="Grad"
              variant="outlined"
              fullWidth
              required
              value={restaurantProfile.city}
              onChange={(e) => handleProfileChange("city", e.target.value)}
            />

            <TextField
              label="JIB broj"
              variant="outlined"
              fullWidth
              required
              inputProps={{ maxLength: 12 }}
              value={restaurantProfile.JIBNumber}
              onChange={(e) =>
                handleProfileChange(
                  "JIBNumber",
                  e.target.value.replace(/\D/g, "")
                )
              }
              helperText="Samo brojevi - mora biti 12 znamenki"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={restaurantProfile.isVATRegistered}
                  onChange={(e) =>
                    handleProfileChange("isVATRegistered", e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Da li ste PDV obveznik?"
            />

            <TextField
              label="Ime restorana"
              variant="outlined"
              fullWidth
              required
              value={restaurantProfile.name}
              onChange={(e) => handleProfileChange("name", e.target.value)}
            />

            <Tooltip title="Dnevna ponuda vrijedi do 13h" arrow>
              <TextField
                label="Dodatni opis: npr. Dnevna ponuda vrijedi do 13h"
                variant="outlined"
                fullWidth
                required
                multiline
                minRows={1}
                value={restaurantProfile.additionalDescription}
                onChange={(e) =>
                  handleProfileChange("additionalDescription", e.target.value)
                }
              />
            </Tooltip>

            <TextField
              label="Kontakt broj koji će se prikazivati na dnevnoj ponudi"
              variant="outlined"
              fullWidth
              required
              value={restaurantProfile.contactNumber}
              onChange={(e) =>
                handleProfileChange("contactNumber", e.target.value)
              }
            />

            {/* Email Addresses */}
            <Typography variant="subtitle1">
              Email adresa na koju će dolaziti dnevne ponude za objavu. Potrebna
              je minimalno 1 email adresa.
            </Typography>
            {[0, 1, 2].map((index) => (
              <TextField
                key={index}
                label={`Email adresa ${index + 1}`}
                variant="outlined"
                fullWidth
                value={restaurantProfile.emailAddresses[index]}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                type="email"
              />
            ))}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={2}
            >
              {restaurantProfile.logo && (
                <Box
                  component="img"
                  src={restaurantProfile.logo}
                  alt="Logo restorana"
                  sx={{ width: 100, height: 100, mb: 2, borderRadius: 1 }}
                />
              )}
              <Button variant="contained" component="label">
                Odaberite logo
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleLogoChange}
                />
              </Button>
            </Box>

            <Box display="flex" gap={2}>
              <FormControl fullWidth sx={{ maxWidth: 100 }}>
                <InputLabel>Predbroj</InputLabel>
                <Select
                  label="Predbroj"
                  name="countryCode"
                  value={restaurantProfile.countryCode}
                  onChange={(e) =>
                    handleProfileChange("countryCode", e.target.value)
                  }
                >
                  <MenuItem value="+387">+387 (BiH)</MenuItem>
                  <MenuItem value="+385">+385 (Hrvatska)</MenuItem>
                  <MenuItem value="+381">+381 (Srbija)</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Broj telefona"
                variant="outlined"
                fullWidth
                required
                type="tel"
                value={restaurantProfile.phone}
                onChange={(e) => handleProfileChange("phone", e.target.value)}
              />
            </Box>

            <Box mt={2} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleProfileSave}
                fullWidth={isMobile}
              >
                SPREMI
              </Button>
            </Box>

            {successMessage && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
        </Paper>
      )}

      {activeTab === "Plaćanje" && (
        <Paper elevation={3} sx={{ padding: isMobile ? 2 : 3, mt: 1 }}>
          <Typography
            variant="h5"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold", mt: 2 }}
          >
            Odaberite Vaš Plan
          </Typography>
          <Typography align="center" color="text.secondary" gutterBottom>
            Izaberite između osnovnog i premium paketa s različitim opcijama
          </Typography>

          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ mt: 3, mb: 2 }}
          >
            <Grid item>
              <Card
                variant="outlined"
                sx={{
                  width: 250,
                  borderColor:
                    selectedPlan === "PRO" ? "primary.main" : "grey.300",
                  borderWidth: selectedPlan === "PRO" ? "2px" : "1px",
                  transition: "0.3s",
                  ":hover": { borderColor: "primary.main", boxShadow: 3 },
                }}
                onClick={() => setSelectedPlan("PRO")}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <PaymentIcon fontSize="large" color="primary" />
                  <Typography variant="h6" sx={{ mt: 1, fontWeight: "bold" }}>
                    PRO Paket
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    50 KM
                  </Typography>
                  <Typography color="text.secondary">
                    Idealno za male timove i osnovne usluge
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <FormControlLabel
                    value="PRO"
                    control={<Radio checked={selectedPlan === "PRO"} />}
                    label="Odaberite"
                  />
                </CardActions>
              </Card>
            </Grid>

            <Grid item>
              <Card
                variant="outlined"
                sx={{
                  width: 250,
                  borderColor:
                    selectedPlan === "GOLD" ? "primary.main" : "grey.300",
                  borderWidth: selectedPlan === "GOLD" ? "2px" : "1px",
                  transition: "0.3s",
                  ":hover": { borderColor: "primary.main", boxShadow: 3 },
                }}
                onClick={() => setSelectedPlan("GOLD")}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <StarIcon fontSize="large" color="secondary" />
                  <Typography variant="h6" sx={{ mt: 1, fontWeight: "bold" }}>
                    GOLD Paket
                  </Typography>
                  <Typography
                    variant="h4"
                    color="secondary"
                    sx={{ fontWeight: "bold" }}
                  >
                    100 KM
                  </Typography>
                  <Typography color="text.secondary">
                    Za veće timove i napredne funkcije
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <FormControlLabel
                    value="GOLD"
                    control={<Radio checked={selectedPlan === "GOLD"} />}
                    label="Odaberite"
                  />
                </CardActions>
              </Card>
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />

          <Typography
            variant="h6"
            align="center"
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            Unesite podatke o kartici
          </Typography>

          <Box
            component="form"
            onSubmit={handlePaymentSubmit}
            sx={{ px: isMobile ? 0 : 5 }}
          >
            <TextField
              label="Broj kartice"
              variant="outlined"
              fullWidth
              required
              type="text"
              inputProps={{ maxLength: 16 }}
              placeholder="1234 5678 1234 5678"
              sx={{ mb: 2 }}
            />
            <Box display="flex" gap={2} sx={{ mb: 2 }}>
              <TextField
                label="Datum isteka"
                variant="outlined"
                required
                fullWidth
                placeholder="MM/YY"
                inputProps={{ maxLength: 5 }}
              />
              <TextField
                label="CVV"
                variant="outlined"
                required
                fullWidth
                type="password"
                inputProps={{ maxLength: 3 }}
              />
            </Box>
            <TextField
              label="Ime na kartici"
              variant="outlined"
              fullWidth
              required
              sx={{ mb: 3 }}
            />
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth={isMobile}
              >
                PLATI {selectedPlan === "PRO" ? "50 KM" : "100 KM"}
              </Button>
            </Box>
          </Box>

          {successMessage && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {successMessage}
            </Alert>
          )}
        </Paper>
      )}
    </Container>
  );
}

export default Dashboard;
