import React, { useState } from "react";
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  CssBaseline,
  Box,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

function Register() {
  const [formData, setFormData] = useState({
    name: "",
    restaurantName: "",
    email: "",
    city: "",
    phoneNumber: "",
    countryCode: "+387",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Mock registration success
    setTimeout(() => {
      setSuccess(true);
      alert("Registration successful!");
    }, 1000);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, mt: 8 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              Registration successful!
            </Alert>
          )}
          <Box component="form" onSubmit={handleRegister} sx={{ mt: 3 }}>
            <TextField
              label="Name"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              label="Restaurant Name"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="restaurantName"
              value={formData.restaurantName}
              onChange={handleChange}
            />
            <TextField
              label="Email Address"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              label="City"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <FormControl fullWidth sx={{ maxWidth: 100 }}>
                <InputLabel>Country Code</InputLabel>
                <Select
                  label="Country Code"
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                >
                  <MenuItem value="+387">+387 (BiH)</MenuItem>
                  <MenuItem value="+385">+385 (Croatia)</MenuItem>
                  <MenuItem value="+381">+381 (Serbia)</MenuItem>
                  {/* Add other codes as needed */}
                </Select>
              </FormControl>
              <TextField
                label="Phone Number"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="phoneNumber"
                type="tel"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Box>
            <TextField
              label="Password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Register
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Register;
