// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Dashboard from "./Dashboard";
import DnevnaPonudaSlika from "./dnevna-ponuda-slika"; // Import DnevnaPonudaSlika component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/dnevna-ponuda-slika"
            element={<DnevnaPonudaSlika />}
          />{" "}
          {/* New route for DnevnaPonudaSlika */}
          {/* Default route redirects to /login */}
          <Route path="/" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
