import React from "react";
import { Box } from "@mui/material";

function DnevnaPonudaSlika() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundImage: 'url("/assets/design_friday.png")', // Correct path
        backgroundSize: "contain", // Change to "contain" to fit the entire image
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat", // Prevents the image from repeating if it doesn't cover the full height
      }}
    />
  );
}

export default DnevnaPonudaSlika;
